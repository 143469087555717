import React from 'react';

import {
    AppBar,
    Button, Checkbox,
    CircularProgress,
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    Drawer,
    FormControlLabel,
    IconButton,
    TextField,
    Toolbar,
    Typography
} from '@material-ui/core/';

import CodeIcon from '@material-ui/icons/Code';
import DeleteIcon from '@material-ui/icons/Delete';
import NavigationClose from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import SearchIcon from '@material-ui/icons/Search';

import JsonDialog from '../../dialogs/JsonDialog';
import OverrideDialog from '../../dialogs/OverrideDialog';

import { SelectCondition } from '@apricityhealth/web-common-lib/components/SelectCondition';
import ChangedByButton from '../../components/ChangedByButton';
import TypeSearch from '@apricityhealth/web-common-lib/components/TypeSearch';
import getErrorMessage from '@apricityhealth/web-common-lib/utils/getErrorMessage';
import EnhancedTable from '@apricityhealth/web-common-lib/components/EnhancedTable';


class ConditionTypeView extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            progress: null,
            dialog: null,
            type: { staged: false, hidden: false },
            modified: false
        };
    }

    componentDidMount() {
        if (this.props.type) {
            this.loadType(this.props.type);
        }
    }

    componentDidUpdate(oldProps) {
        if (this.props.type !== oldProps.type) {
            this.loadType(this.props.type);
        }
    }

    loadType(type) {
        if (this.state.modified === true) {
            return this.displayModifiedDialog(this.loadType.bind(this, type));
        }

        console.log("loadType:", type );
        this.setState({
            type: JSON.parse(JSON.stringify(type)),
            progress: null,
            dialog: null
        });
    }

    closeType() {
        if (this.state.modified === true) {
            this.displayModifiedDialog(this.props.onClose);
        }
        else
            this.props.onClose();
    }

    displayModifiedDialog(done) {
        this.setState(
        {
            dialog: <Dialog open>
                <DialogTitle>Condition Type Modified!</DialogTitle>
                <DialogContent>This condition type has been modified, would you like to save any changes?</DialogContent>
                <DialogActions>
                    <Button variant='contained' style={styles.button} onClick={e => {
                        this.setState({ modified: false, dialog: null });
                        this.saveType(done, null);
                    }}>Yes</Button>
                    <Button variant='contained' style={styles.button} onClick={e => {
                        this.setState({ modified: false, dialog: null }, done);
                    }}>No</Button>
                </DialogActions>
            </Dialog>
        });
    }

    closeSearch() {
        this.setState({ dialog: null });
    }

    displayOverrideDialog(oldPlanId, callback) {
        this.setState({
            dialog: <OverrideDialog appContext={this.props.appContext}
                oldPlanId={oldPlanId} parent={this} onConfirm={callback} />
        });
    }

    saveType(callback, planId = null) {
        const { appContext } = this.props;
        const { type } = this.state;

        if (!planId && type.planId && type.planId !== appContext.state.plan.planId) {
            return this.displayOverrideDialog(
                type.planId, this.saveType.bind(this, callback)
            );
        } 

        this.setState({ progress: <CircularProgress size={20} />, error: null });
        appContext.stores.DataTypesStore.saveCondition(type, planId).then((type) => {
            this.setState({ modified: false, override: false, progress: null, type }, callback);
        }).catch((error) => {
            this.setState({ progress: null, error: getErrorMessage(error) });
        });
    }

    deleteType() {
        const { type } = this.state;
        this.setState(
        {
            dialog: <div><Dialog open model='false'>
                <DialogTitle>Delete Condition: {`${type.name}`}</DialogTitle>
                <DialogContent>
                    <DialogContentText>Are you sure you want to delete?</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' style={styles.button}
                        onClick={this.cancelDeleteType.bind(this)}>Cancel</Button>,
                    <Button variant='contained' style={styles.button}
                        onClick={this.confirmDeleteType.bind(this)}>Confirm</Button>
                </DialogActions>
            </Dialog></div>
        });
    }

    cancelDeleteType() {
        this.setState({ dialog: null });
    }

    confirmDeleteType() {
        const { appContext } = this.props;
        const { type } = this.state;

        this.setState({ dialog: null });
        if (type.conditionId) {
            this.setState({ progress: <CircularProgress size={20} />, error: null });
            appContext.stores.DataTypesStore.deleteCondition(type.conditionId, type.planId).then( () => {
                this.setState({ progress: null });
                this.closeType();
            }).catch( error => {
                console.log('deleteCondition error:', error.response);
                this.setState({ progress: null, error: getErrorMessage(error) });
            });
        }
    }

    showConditionJSON() {
        const { appContext } = this.props;
        const { type } = this.state;

        this.setState(
        {
            dialog: <JsonDialog
                appContext={appContext}
                dataType={type}
                onEditDone={(type) => this.setState({ type, dialog: null })}
                onDone={() => this.setState({ dialog: null })}
            />
        });
    }

    search(args) {
        const { props: { appContext } } = this,
            { code, system, search } = args;

        this.setState(
        {
            progress: null,
            dialog: <Drawer open anchor='right' variant='persistent'>
                <TypeSearch
                    appContext={appContext}
                    searchType='conditions'
                    code={code}
                    system={system}
                    search={search}
                    closeSearch={this.closeSearch.bind(this)}
                    itemSelected={this.updateType.bind(this)}
                />
            </Drawer>
        });
    }

    render() {
        const { appContext } = this.props;
        const { dialog, progress, error, type } = this.state;

        const stagesColumns = [
            { id: 'name', label: 'Name', editType: 'text' },
            { id: 'type', label: 'Type', editType: 'text' },
            { id: 'value', label: 'Value', editType: 'text' },
        ]

        return <div align='center' width='500'>
            <AppBar style={styles.appBar} position='static'><Toolbar>
                <IconButton onClick={() => this.closeType()}><NavigationClose /></IconButton>
                <Typography variant='h6' color='inherit'>Condition Type</Typography>
            </Toolbar></AppBar>
            <div align='left' style={styles.div}><div style={styles.table}><div>
                <div style={styles.buttonHeader}>
                    <div>Condition ID: {type.conditionId}</div>
                    <div style={styles.delete} valign='top'>
                        <span style={{color: 'red'}}>{error}</span>
                        <IconButton disabled={progress !== null} onClick={this.saveType.bind(this, null, null)}>{progress || <SaveIcon />}</IconButton>
                        <IconButton onClick={this.showConditionJSON.bind(this)}><CodeIcon /></IconButton>
                        <IconButton onClick={this.deleteType.bind(this)}><DeleteIcon /></IconButton>
                    </div>
                </div>

                <br />
                <TextField name='name' label='Name' style={styles.question} value={type.name}
                    onChange={(e) => { type.name = e.target.value; this.setState({type, modified: true }) }} />
                <IconButton disabled={!type.name} onClick={() => this.search({ search: type.name })}>
                    <SearchIcon />
                </IconButton>

                <br />
                <TextField name='description' label='Description' style={styles.question}
                    value={type.description} onChange={(e) => { type.description = e.target.value; this.setState({type, modified: true})}} />
                <IconButton disabled={!type.description} onClick={
                    e => void this.search({ search: type.description })}><SearchIcon />
                </IconButton>

                <br />
                <TextField name='code' label='Code ID' style={styles.question}
                    value={type.code} onChange={(e) => { type.code = e.target.value; this.setState({type, modified: true })}} />
                <IconButton disabled={!type.code} onClick={() => this.search({ code: type.code, system: type.system })}>
                    <SearchIcon />
                </IconButton>
                <br />
                <TextField name='system' label='System' style={styles.question} value={type.system}
                    onChange={(e) => { type.system = e.target.value; this.setState({ type, modified: true }) }} />
                <br />
                <SelectCondition enableNone label='Parent Condition' appContext={appContext}
                    conditionId={type.parentId} onSelect={(parent) => { 
                        let parentId = parent ? parent.conditionId : '';
                        if ( parentId !== type.parentId ) {
                            type.parentId = parentId;
                            this.setState({type, modified: true }) 
                        }
                    }} />
                <br />
                <FormControlLabel key='hidden' label='Hidden' control={
                    <Checkbox name='hidden' checked={type.hidden} onChange={(e,v) => { type.hidden = v; this.setState({type, modified: true })}} />
                } />
                <br />
                <FormControlLabel key='staged' label='Staged' control={
                    <Checkbox name='staged' checked={type.staged} onChange={(e,v) => { console.log("staged:", v); type.staged = v; this.setState({type, modified: true })}} />
                } />

                <br />
                { type.staged ? <EnhancedTable 
                    columnData={stagesColumns}
                    orderBy='name'
                    data={type.stages}
                    disableMultiSelect={false}
                    onDataChanged={(data) => {
                        type.stages = data;
                        this.setState({type, modified: true});
                    }}
                    title='Stages'
                    rowsPerPage={5}
                    /> : null
                }
                <ChangedByButton collection='ConditionTypeModel' appContext={appContext}
                    primaryKey={type.conditionId}
                />
            </div></div></div>
            {dialog}
        </div>;
    }
};


/** @todo return tags */
const styles = {
    appBar: {
        backgroundColor: '#FF9800',
        width: '1000px'
    },
    button: { margin: 10 },
    buttonHeader: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between'
    },
    checkbox: { marginBottom: 16 },
    div: {
        margin: 10,
        padding: 10
    },
    flex: { flex: 1 },
    menuButton: {
        marginLeft: -12,
        marginRight: 20
    },
    menuExpansion: {
        background: '#ffffff',
        justifyContent: 'left',
        width: '800px'
    },
    menuExpansionDetails: {
        background: '#ffffff',
        padding: '0px',
        width: '800px'
    },
    menuExpansionSummary: { justifyContent: 'left' },
    menuList: {
        // backgroundColor: 'pink',
        justifyContent: 'left',
        width: '500px'
    },
    openButton: { margin: 15 },
    question: {
        margin: 5,
        width: '80%'
    },
    sectionRow: {
        marginBottom: 15,
        marginLeft: 10,
        marginRight: 10,
        marginTop: 15
    },
    select: {
        margin: 10,
        width: 200
    },
    tab: { backgroundColor: 'lightblue' },
    table: { width: '100%' },
    td: { textAlign: 'right' },
    tags: { margin: 5 },
    text: { margin: 5 }
};

export default ConditionTypeView;