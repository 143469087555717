import React from "react";
import Moment from 'moment';

import {
    Typography,
    List,
    CircularProgress,
    FormControlLabel,
    Checkbox,
    Dialog,
    DialogTitle,
    DialogContent,
    InputLabel,
    TextField,
    FormControl,
    Select,
    MenuItem,
    DialogActions,
    Button,
    IconButton,
    Tooltip
} from '@material-ui/core/';

import RestoreIcon from "@material-ui/icons/Restore";

import { isArrayValid } from '../utils/Utils';
import User from "@apricityhealth/web-common-lib/components/User";
import EnhancedTable from "@apricityhealth/web-common-lib/components/EnhancedTable";
import { toBoolean } from '@apricityhealth/web-common-lib/utils/Utils';
import { deleteSnapshot, restoreSnapshot, saveSnapshot } from "./ContentView";
import getErrorMessage from "@apricityhealth/web-common-lib/utils/getErrorMessage";
import Plan from "@apricityhealth/web-common-lib/components/Plan";

class ContentActivityView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            progress: null,
            error: null,
            dialog: null,
            description: '',
            recurring: false,
            interval: 1,
            intervalType: 'day'
        };
    }

    componentDidUpdate() {
        console.log("ContentActivityView.componentDidUpdate")
    }

    generateActivityView(activity) {
        if ( activity === null) {
            return <div align='center'><CircularProgress /></div>
        }
        const { appContext } = this.props;

        if (isArrayValid(activity)) {
            let rows = [];
            activity.forEach((k) => {
                rows.push(
                    <div key={k._id}>
                        <Typography style={{ marginTop: '2px' }} align='left' variant="subtitle1" color="inherit" >
                            Author: <User appContext={appContext} userId={k.userId} userIdHidden={true} />
                        </Typography>
                        <Typography style={{ marginTop: '2px' }} align='left' variant="subtitle2" color="secondary" >
                            Date: {(k.updatedAt !== null && k.updatedAt !== '') ? Moment(k.updatedAt).local().format("MM-DD-YYYY HH:mm") : ''}
                        </Typography>
                        <Typography style={{ marginTop: '2px' }} align='left' variant="subtitle2" color="secondary" >
                            Type: {k.activityType}
                        </Typography>
                        <pre>{k.comment}</pre>
                        <div style={{ marginTop: '10px' }} />
                    </div>);
            });

            return <div>
                <Typography margintop='20px' align='left' variant="h6" color="inherit" >
                    {activity.length} updates
                </Typography>
                <List
                    style={{ marginTop: '10px' }}
                    height={1000}
                    width={'90%'}
                >
                    {rows}
                </List>
            </div>;
        } else {
            return <div><h2>No activity.</h2></div>
        }
    }

    onAddSnapshot() {
        const { description, recurring, interval, intervalType } = this.state;
        const { parent, appContext, plan } = this.props;

        console.log("onAddSnapshot");
        this.setState({dialog: <Dialog open={true}>
            <DialogTitle>Create Snapshot</DialogTitle>
            <DialogContent>
                Please confirm creation of a snapshot for <Plan appContext={appContext} planId={plan.planId} />?
                <br />
                <TextField style={{ width: 400, margin: 5}} value={description} label='Description' 
                    onChange={(e) => this.setState({description: e.target.value}, this.onAddSnapshot.bind(this))} />
                <FormControlLabel label='Recurring Snapshot?' control={<Checkbox checked={recurring} onChange={(e,v) => {
                        this.setState({recurring: v}, this.onAddSnapshot.bind(this))
                    }} />} />
                {recurring && <br />}
                {recurring && 
                    <TextField style={{ width: 200, margin: 5}} label='Interval' value={interval} type='number' onChange={(e) => this.setState({interval: e.target.value}, this.onAddSnapshot.bind(this))} />}
                {recurring && <br />}
                {recurring &&
                    <FormControl style={{ width: 200, margin: 5 }}>
                    <InputLabel htmlFor="freq">Frequency</InputLabel>
                    <Select value={intervalType}
                        onChange={(e) => this.setState({intervalType: e.target.value}, this.onAddSnapshot.bind(this)) }>
                        <MenuItem value='year'>Annually</MenuItem>
                        <MenuItem value='month'>Monthly</MenuItem>
                        <MenuItem value='week'>Weekly</MenuItem>
                        <MenuItem value='day'>Daily</MenuItem>
                        <MenuItem value='hour'>Hourly</MenuItem>
                    </Select>
                </FormControl>}
            </DialogContent>
            <DialogActions>
                <Button style={{margin: 5}} onClick={() => this.setState({dialog: null})}>Cancel</Button>
                <Button style={{margin: 5}} onClick={() => {
                    this.setState({dialog: null, error: null}, () => {
                        saveSnapshot(appContext, {
                            description,
                            recurring,
                            interval,
                            intervalType
                        }).then(parent.loadContent.bind(parent))
                        .catch((err) => {
                            this.setState({error: getErrorMessage(err)})
                        })
                    })
                }}>Ok</Button>
            </DialogActions>
        </Dialog>})
    }   

    onDeleteSnapshot(snapshot, index) {
        const { parent, appContext } = this.props;

        console.log("onDeleteSnapshot:", snapshot, index );
        this.setState({dialog: <Dialog open={true}>
            <DialogTitle>Please Confirm Delete</DialogTitle>
            <DialogContent>Are you sure you want to delete snapshot {snapshot.snapshotId}?</DialogContent>
            <DialogActions>
                <Button style={{ margin: 5}} onClick={() => this.setState({dialog: null})}>Cancel</Button>
                <Button style={{ margin: 5}} onClick={() => {
                    this.setState({ dialog: null, error: null}, () => {
                        deleteSnapshot(appContext, snapshot.snapshotId).then(parent.loadContent.bind(parent))
                            .catch((err) => {
                                this.setState({error: getErrorMessage(err)});
                            })
                    });
                }}>Confirm</Button>
            </DialogActions>
        </Dialog>})
    }

    onRestoreSnapshot( snapshot ) {
        const { parent, appContext } = this.props;

        console.log("onRestoreSnapshot:", snapshot );
        this.setState({dialog: <Dialog open={true}>
            <DialogTitle>Please Confirm Restore</DialogTitle>
            <DialogContent>Are you sure you want to restore plan <Plan appContext={appContext} planId={snapshot.planId} /> to this snapshot?<br /><br />
            NOTE: This will take several minutes before it is applied.
            </DialogContent>
            <DialogActions>
                <Button style={{ margin: 5}} onClick={() => this.setState({dialog: null})}>Cancel</Button>
                <Button style={{ margin: 5}} onClick={() => {
                    this.setState({ dialog: null, error: null}, () => {
                        restoreSnapshot(appContext, snapshot.snapshotId).then(parent.loadContent.bind(parent))
                            .catch((err) => {
                                this.setState({error: getErrorMessage(err)})
                            })
                    })
                }}>Confirm</Button>
            </DialogActions>
        </Dialog>})
    }

    render() {
        const { dialog, error } = this.state;
        const { appContext, parent: { state: { snapshots }}} = this.props;
        const columnData = [
            //{ id: 'snapshotId', label: 'ID' },
            { id: 'runTime', label: 'Run Time', formatValue: (v) => Moment(v).toISOString() },
            { id: 'description', label: 'Description' },
            { id: 'recurring', label: 'Recurring', formatValue: (v,r) => {
                console.log("r:", r );
                return toBoolean(v) ? `${r.interval}/${r.intervalType}` : "No" 
            }},
            { id: 'status', label: 'Status' },
            { id: 'userId', label: 'User', formatValue: (v) => <User appContext={appContext} userId={v} /> }
        ];

        return <div>
            <EnhancedTable
              onActions={(table, numSelected, actions) => {
                    if ( numSelected === 1 ) {
                        const snapshot = table.state.data[table.state.selected[0]];
                        actions.unshift( <Tooltip key='restore' title='Restore Snapshot'>
                            <IconButton onClick={this.onRestoreSnapshot.bind(this,snapshot)}>
                                <RestoreIcon />
                            </IconButton>
                        </Tooltip>);
                    }
                }}
                data={snapshots}
                orderBy='runTime'
                order='desc'
                columnData={columnData}
                title='Snapshots' 
                onAdd={this.onAddSnapshot.bind(this)}
                onDelete={this.onDeleteSnapshot.bind(this)}
            />
            <span style={{ color: 'red'}}>{error}</span>
            {dialog}
        </div>
    }
}

export default ContentActivityView;

