import React from 'react';
import PubSub from 'pubsub-js';

import {
    Checkbox,
    Drawer,
    FormControlLabel,
    IconButton,
    TextField,
    Tooltip,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button
} from '@material-ui/core/';

import { withStyles } from '@material-ui/core/styles';

import CreateIcon from '@material-ui/icons/Create';
import RefreshIcon from '@material-ui/icons/Refresh';
import EnhancedTable from "@apricityhealth/web-common-lib/components/EnhancedTable";
import Plan from '@apricityhealth/web-common-lib/components/Plan';
import getErrorMessage from '@apricityhealth/web-common-lib/utils/getErrorMessage';
import ConditionTypeView from './ConditionTypeView';

const Promise = require('bluebird');

function testFilter(filter, condition) {
    if (condition) {
        filter = filter.toLowerCase();
        if (condition.name && condition.name.toLowerCase().indexOf(filter) >= 0)
            return true;
        if (condition.description && condition.description.toLowerCase().indexOf(filter) >= 0)
            return true;
        if (condition.system && condition.system.toLowerCase().indexOf(filter) >= 0)
            return true;
        if (condition.code && condition.code.toLowerCase().indexOf(filter) >= 0)
            return true;
        if (condition.conditionId && condition.conditionId.toLowerCase().indexOf(filter) >= 0)
            return true;
        if (Array.isArray(condition.tags)) {
            for (var i = 0; i < condition.tags.length; ++i) {
                if (condition.tags[i].toLowerCase().indexOf(filter) >= 0)
                    return true;
            }
        }
    }
    return false;
};


/**
 * Condition Terminology Portal `e.g. Clinical Ontology [Tree|Web|List] Viewer`
 * @memberof module:Rosetta
 * @alias ConditionTypesView
 */
export class ConditionTypesView extends React.Component {
//#region Component-Lifecycle Methods
    constructor (props) {
        super(props);
        this.state = {
            dialog: null,
            dependencies: true,
            invalids: false,
            drawerOpen: false,
            filter: '',
            filtered: null,
            types: [],
            typeHash: {},
            deleteTypes: []
        };
    }

    componentDidMount() {
        this.token = PubSub.subscribe('STORE_UPDATE', this.loadContent.bind(this));
        this.loadContent();
    }

    componentWillUnmount() {
        PubSub.unsubscribe(this.token);
    }

    loadContent() {
        const { appContext } = this.props;
        const { plan } = appContext.state;        
        if ( plan ) {
            const { planId } = plan;
            const { dependencies, invalids } = this.state;

            const store = appContext.stores.DataTypesStore;
            const types = store.getConditions().filter((e) => (dependencies === true || e.planId === planId) && (invalids === false || !e.name));
            const typeHash = store.getConditionsHash();

            this.setState({types, typeHash }, this.updateFilter.bind(this));
        }
    }
//#endregion

    /**
     * Ingest a `delimeted-value` file(s) containing Conditions, then PUSH-DIFF w/ Apricity Plan/DB
     * @param {Event} e Source Event Element `ie. File <input>.onChange emitted`
     * @example
     * {
     *     ...
     *     target: EventTarget {
     *         ...
     *         files: FileList // File Collection input via Native Browser->OS Controls
     *     }
     * } = e;
     * 
     * @throws {Error|MongooseError} Invalid args | Error updating
     */
    // importBulkConditions() {
    //     const {
    //         props: { appContext },
    //         state: { drawerOpen, types }
    //     } = this;

    //     if (drawerOpen) throw new EvalError('`ConditionTypes.Drawer` is open, csvImport aborted');

    //     this.setState(
    //     { // Pass the selected Files & Save event-listener [this.bound] to BulkConditionsView slide
    //         dialog: <Drawer open variant='persistent' anchor='right'
    //             onClose={this.onCloseDialog.bind(this)}
    //         >
    //             <BulkUploadConditionsView appContext={appContext} onSave={
    //             (/**@this {ConditionTypesView}*/function (conditions, close = true) {
    //                 console.log('ConditionTypes.BulkSave:', conditions.length);
    //                 if (! isArrayValid(conditions)) return this.onCloseDialog();

    //                 const diff = [];
    //                 for (const c of /**@type {ConditionType[]}*/(conditions)) {
    //                     const found = this.find(c.system, c.code);
    //                     if (! found) diff.push(c);
    //                 }
    //                 console.log('New Conditions:', diff.length);
    //                 return saveCondition(appContext, diff).then( saved => void this.setState(
    //                     { types: [...types, ...saved] }
    //                 )).then(
    //                     () => (close === true && this.onCloseDialog())
    //                 ).catch( err => {
    //                     console.error('ConditionTypes.BulkSave:', err);
    //                 });
    //             }).bind(this)} />
    //         </Drawer>
    //     });
    // }

    /**
     * Search the ConditionTreeTypes for specified value
     * 
     * @param {String} system Ontology-Terminology System
     * @param {String} code Assigned coding by `system`
     * @param {String} [description] Human-readable code
     * @returns {ConditionType|undefined} Condition, if found
     */
    // find(system, code, description = '') {
    //     const { state: { types } } = this;
    // /* Validate inputs | Log errors */
    //     if (! isArrayValid(types)) return void console.error('no type data <Array>');
    //     if (typeof code !== 'string') return void console.error(
    //         'parameter `code` must be a string');
    //     if (typeof description !== 'string') return void console.error(
    //         'parameter `description` must be a string');

    //     console.info(`finding condition ${system}, ${code} [ ${description || 'no-description'} ]`);


    //     switch (system) {
    //         case TypeOntology.Systems.ICD10.Url:
    //         case TypeOntology.Systems.LOINC.Url:
    //         case TypeOntology.Systems.NCBO.Url:
    //         case TypeOntology.Systems.NDC.Url:
    //         case TypeOntology.Systems.RxNORM.Url:
    //         case TypeOntology.Systems.SNOMED.Url:
    //             return types.find(
    //                 c => c.system.startsWith(system) && c.code === code);
    //         default:
    //             return void console.warn('ontology system unsupported');
    //     };
    // }
//#endregion

    updateFilter() {
        const { filter, types, typeHash } = this.state;

        if (filter) {
            let filtered = [];
            for (let i = 0; i < types.length; ++i) {
                const type = types[i];
                if (testFilter(filter, type)) {
                    filtered.push(type);
                }
                else if (type.parentId && typeHash[type.parentId]) {
                    if ( testFilter(filter, typeHash[type.parentId])) {
                        filtered.push(type);
                    }
                }
            }

            this.setState({ filtered });
        }
        else {
            this.setState({ filtered: null });
        }
    }

    onCloseDialog( reload = true ) {
        this.setState({ dialog: null }, reload ?  this.loadContent.bind(this) : undefined);
    }

    onEditType(table) {
        const { selected, data } = table.state;
        const { appContext } = this.props;

        if ( selected.length > 0) {
            let type = data[selected[0]];
            if ( type ) {
                this.setState(
                {
                    dialog: <Drawer open variant='persistent' anchor='right'>
                        <ConditionTypeView appContext={appContext} type={type}
                            onClose={this.onCloseDialog.bind(this)} />
                    </Drawer>
                });
                table.setState( { selected: [] });
            }
        }
    }

    onCreateType() {
        const { props: { appContext } } = this;
        const newType = { conditionId: '', description: '' };

        this.setState(
        {
            dialog: <Drawer open variant='persistent' anchor='right'>
                <ConditionTypeView appContext={appContext} type={newType} 
                    onClose={this.onCloseDialog.bind(this)} />
            </Drawer>
        });
    }

    onDeleteType(type) {
        console.log("onDeleteType:", type );
        const { deleteTypes } = this.state;
        deleteTypes.push( type );

        let dialog = <Dialog open={true}>
            <DialogTitle>Confirm Delete</DialogTitle>
            <DialogContent>Please confirm the deletion of {deleteTypes.length} condition types?</DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    this.setState({dialog: null, deleteTypes: [] });
                }}>Cancel</Button>
                <Button onClick={() => {
                    const { appContext } = this.props;

                    this.setState({dialog: null, error: null, deleteTypes: [] });
                    Promise.map( deleteTypes, (type) => {
                        return appContext.stores.DataTypesStore.deleteCondition(type.conditionId, type.planId);
                    }, { concurrency: 20 }).then(() => {
                        this.loadContent();
                    }).catch((err) => {
                        this.setState({error: getErrorMessage(err)});
                    })
                }} >Confirm</Button>
            </DialogActions>
        </Dialog>;
        this.setState({dialog});
    }

    render() {
        const {
            props: {
                appContext: {
                    stores: {
                        DataTypesStore: store
                    }
                }
            },
            state: {
                filter,
                dependencies,
                invalids,
                dialog, 
                error, 
                types,
                filtered,
                typeHash
            }
        } = this;

        const typesColumns = [
            { id: 'conditionId', label: 'Condition ID' },
            { id: 'parentId', label: 'Parent', formatValue: (i) => (typeHash[i] || { name: i}).name },
            { id: 'name', label: 'Name', formatValue: (v) => v.substring(0,32) },
            { id: 'description', label: 'Description', formatValue: (v) => v.substring(0,64) },
            { id: 'system', label: 'System', formatValue: (v) => v.substring(0,32) },
            { id: 'code', label: 'Code', formatValue: (v) => v.substring(0,16) },
            { id: 'planId', label: 'Plan', formatValue: (i) => {
                return <Plan appContext={this.props.appContext} planId={i} />
            }}
        ];

        return <table width='100%'><tbody>
            <tr>
                <td>
                    <TextField label='Filter'
                        value={filter}
                        style={divStyles.filterField}
                        onChange={ e => {
                            this.setState({ filter: e.target.value }, this.updateFilter.bind(this));
                        }}
                    />
                    <FormControlLabel label='Show Dependencies'
                        style={{ margin: 5 }}
                        control={
                            <Checkbox checked={dependencies} onChange={(e, v) => {
                                this.setState({ dependencies: v }, this.loadContent.bind(this));
                            }} />
                        }
                    />
                    <FormControlLabel label='Show Invalids'
                        style={{ margin: 5 }}
                        control={
                            <Checkbox checked={invalids} onChange={(e, v) => {
                                this.setState({ invalids: v }, this.loadContent.bind(this));
                            }} />
                        }
                    />
                </td>
                <td align='right'>
                    <span style={{color: 'red'}}>{error}</span>
                    {/*<IconButton disabled={progress !== null}
                        onClick={this.importBulkConditions.bind(this)}><PublishIcon />
                    </IconButton>*/}
                    <IconButton disabled={store.progress !== null} onClick={store.loadDataTypes.bind(store)}>
                        {store.progress || <RefreshIcon />}
                    </IconButton>
                </td>
            </tr>
            <tr>
                <td colSpan='2'>
                    <EnhancedTable
                        onTable={(table) => this._table = table}
                        disableMultiSelect={false}
                        onActions={(table, numSelected, actions) => {
                            if (numSelected === 1) {
                                actions.unshift(<Tooltip title='Edit' key='edit'><IconButton onClick={this.onEditType.bind(this, table)}><CreateIcon /></IconButton></Tooltip>);
                            }
                        }}
                        onAdd={this.onCreateType.bind(this)}
                        onDelete={this.onDeleteType.bind(this)}
                        orderBy='conditionId'
                        columnData={typesColumns}
                        data={filtered || types}
                        title='Condition Types' />
                    {dialog}
                </td>
            </tr>
        </tbody></table>;
    }
};


const styles = theme => ({ drawerPaper: { marginTop: '65px' } });

const divStyles = {
    filterField: {
        marginLeft: '20px',
        marginTop: '10px',
        width: '500px'
    },
    header: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'no-wrap'
    }
};

export default withStyles(styles, { withTheme: true })(ConditionTypesView);